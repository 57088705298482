import React from "react";
import ReactGA from 'react-ga4'

 
const TERMS_OF_SERVICE = `Terms of Service

Welcome to [Web App Name]! By using our service, you agree to these terms. Please read them carefully.

Use at Your Own Risk

[Web App Name] is provided "as is" and "as available." We make no warranties or representations about the suitability, reliability, availability, timeliness, or accuracy of the service for any purpose. You use [Web App Name] at your own risk.

No Warranties

We disclaim all warranties, whether express, implied, or statutory, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that [Web App Name] will meet your requirements or that the service will be uninterrupted, secure, or error-free.

Limitation of Liability

In no event shall we be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, or goodwill, arising out of or in connection with the use or performance of [Web App Name], even if we have been advised of the possibility of such damages.

Indemnity

You agree to indemnify and hold harmless [Web App Name] and its contributors from and against any claims, liabilities, damages, losses, and expenses, including without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your use of [Web App Name] or your violation of these terms.

Changes to Terms

We may update these terms from time to time. We will notify you of any changes by posting the new terms on this page. Your continued use of [Web App Name] after any such changes constitutes your acceptance of the new terms.

Governing Law

These terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.`

const ToS = () => {
  ReactGA.send({ hitType: "pageview", page: `/terms`, title: `ToS` });

    return (
        <div className="container">
        <div className="row">
          <div className="col-sm">
          <h1>Terms of Service:</h1><br></br>
          <p>
          Welcome to REPL! By using our service, you agree to these terms. Please read them carefully.<br/><br/><br/>
            <h4>Use at Your Own Risk</h4><br/>
            REPL is provided "as is" and "as available." We make no warranties or representations about the suitability, reliability, availability, timeliness, or accuracy of the service for any purpose. You use REPL at your own risk.<br/><br/><br/>
            <h4>No Warranties</h4><br/>
            We disclaim all warranties, whether express, implied, or statutory, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that REPL will meet your requirements or that the service will be uninterrupted, secure, or error-free.<br/><br/><br/>
            <h4>Limitation of Liability</h4><br/>
            In no event shall we be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, or goodwill, arising out of or in connection with the use or performance of REPL, even if we have been advised of the possibility of such damages.<br/><br/><br/>
            <h4>Indemnity</h4> <br/>
            You agree to indemnify and hold harmless REPL and its contributors from and against any claims, liabilities, damages, losses, and expenses, including without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your use of REPL or your violation of these terms.<br/><br/><br/>            
            <h4>Third-Party Links</h4><br/>
            Our website may contain links to third-party websites. We are not responsible for the privacy practices or
            content of these websites.<br/><br/><br/>
            <h4>Changes to Terms</h4><br/>
            We may update these terms from time to time. We will notify you of any changes by posting the new terms on this page. Your continued use of REPL after any such changes constitutes your acceptance of the new terms.<br/><br/><br/>
            <h4>Governing Law</h4><br/>
            These terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
          </p>
          </div>
        </div>
      </div>
    );
};
 
export default ToS;