const References = () => {
  return (
    <div className="container-fluid">
        <div className="row">
          <div className = "col-12">
          <h3>Caveats</h3>
        <ul>
        <li>Holidays are political, and each jurisdiction and/or trading venue (or unique tradeable instrument) has unique methods to proclaim or repeal.</li>
        <li>This is only designed to be used for "human life" dates, such as -100y ago and +100y from now.</li> 
        <li>In the future we might add support for sub-day time periods (hours, minutes, ...)</li>
        <li>We used the <code>workalendar</code> (thank you!) library to generate a handful of holiday lists. Holiday lists should always be verified by a human for financial purposes.</li>
</ul>

<h3>Acknowledgements</h3>

<p>Thank you to the teams behind <code>dateutil</code>, <code>datetime</code>, <code> workalendar</code>, <code>pandas</code>, <code>numpy</code>, ICMA, ISDA, ISO 8601, and the derivatives technologists and BO people worldwide.</p>
<p>It is our hope that fixed income and FI math can be a little less scary for everyone, because every single person in the world is impacted by it.</p>

<p>The <a href='https://www.disent.com'>Disent</a> Team</p>

<h4>References</h4>
<ul>
  <li><a href="https://en.wikipedia.org/wiki/ISO_8601">https://en.wikipedia.org/wiki/ISO_8601</a></li>
  <li><a href="https://en.wikipedia.org/wiki/Day_count_convention">https://en.wikipedia.org/wiki/Day_count_convention</a></li>
  <li><a href="https://en.wikipedia.org/wiki/Date_rolling">https://en.wikipedia.org/wiki/Date_rolling</a></li>
  <li><a href="https://www.amazon.com/Calendrical-Calculations-Edward-M-Reingold/dp/0521771676">https://www.amazon.com/Calendrical-Calculations-Edward-M-Reingold/dp/0521771676</a> (Thanks Prof!)</li>
  <li><a href="https://www.amazon.com/Fixed-Income-Mathematics-Fifth-Statistical/dp/1264258275">https://www.amazon.com/Fixed-Income-Mathematics-Fifth-Statistical/dp/1264258275</a></li>
</ul>
          </div>
        </div>
    </div>
  );
};

export default References;
