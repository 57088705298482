import React, { useCallback, useState, useMemo, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";

import './accordion.css'

const SESSION_STORAGE_KEY = 'DISENT:DATEROLL-DOCS'

const Accordion = (props) => {
    const { 
        buildJSON,
        onChangeEndpoint=()=>{}, 
        onClickExpandable=()=>{}
  } = props;

  //-------------USE STATES-------------------
  const [currentExpandables, setCurrentExpandables] = useState(JSON.parse(window?.sessionStorage?.getItem(SESSION_STORAGE_KEY))?.currentExpandables ? JSON.parse(window?.sessionStorage?.getItem(SESSION_STORAGE_KEY))?.currentExpandables : []);
  const [currentEndpoint, setCurrentEndpoint] = useState(JSON.parse(window?.sessionStorage?.getItem(SESSION_STORAGE_KEY))?.currentEndpoint ? JSON.parse(window?.sessionStorage?.getItem(SESSION_STORAGE_KEY))?.currentEndpoint : undefined);
//--------------USE MEMOS------------------------

const expandableStyle = useMemo(() => {
    return {
      border: "none",
      backgroundColor: "white",
    };
  }, []);
  //------------USE EFFECTS---------------
  const getArrow = useCallback(
    (expandable) => {
      if (currentExpandables.includes(expandable)) {
        return "▾";
      } else {
        return "▸";
      }
    },
    [currentExpandables]
  );

  const toggleExpandables = useCallback(
    (e, expandable) => {
      let expandableSet = new Set(currentExpandables);
      if (expandableSet.has(expandable)) {
        //remove
        expandableSet.delete(expandable);
        setCurrentExpandables([...expandableSet]);
      } else {
        //add
        expandableSet.add(expandable);
        setCurrentExpandables([...expandableSet]);
      }
    },
    [currentExpandables]
  );

const onEndpointClick = useCallback((e, endpoint) => {
    setCurrentEndpoint(endpoint);
    onChangeEndpoint(endpoint)
  }, [onChangeEndpoint]);

const onExpandableClick= useCallback((e,expandable)=>{
    onClickExpandable(expandable)
},[onClickExpandable])




const getAccordionBaseCase= useCallback((name)=>{
    return(
    <li id={name} className="endpoint" key={`${name}:${name}`} style={{ fontWeight:currentEndpoint===name ? 'bold' : 'normal'}} onClick={(e)=>onEndpointClick(e,name)}>
    <Link to={encodeURI(name)} style={{textDecoration:'none',color:"black"}}><span style={{visibility:'hidden'}}>{`${getArrow(name)}`}</span>{` ${name}`}</Link>   
    </li>     
    )
},[currentEndpoint,onEndpointClick])



const getAccordion = useCallback((dict)=>{ 
    return(Object.entries(dict).map((entry)=>{
        const [key,val] = entry
        if(val==='stop'){
            //base case
            return getAccordionBaseCase(key)
        }
        else{
                //recurse case
            return(
            <li>
            <div className="expandable">
                <div className="expandable-button">
                <button
                  onClick={(e) => {
                    toggleExpandables(e, key);
                  }}
                  style={expandableStyle}
                >
                  <span>{`${getArrow(key)}`}</span>{` ${key}`}
                </button>
              </div>
                <ul className='expandable-list'>
                {currentExpandables.includes(key) && getAccordion(val)}
                </ul>
            </div>
            </li>)
        }
    }))
},[currentExpandables,expandableStyle,getAccordionBaseCase,getArrow,toggleExpandables])


useEffect(()=>{
  let oldStorage = window.sessionStorage.getItem(SESSION_STORAGE_KEY) ? JSON.parse(window.sessionStorage.getItem(SESSION_STORAGE_KEY)) : {}
  oldStorage["currentExpandables"] = currentExpandables
  window.sessionStorage.setItem(SESSION_STORAGE_KEY,JSON.stringify(oldStorage))
},[currentExpandables])

useEffect(()=>{
  let oldStorage = window.sessionStorage.getItem(SESSION_STORAGE_KEY) ? JSON.parse(window.sessionStorage.getItem(SESSION_STORAGE_KEY)) : {}
  oldStorage["currentEndpoint"] = currentEndpoint
  window.sessionStorage.setItem(SESSION_STORAGE_KEY,JSON.stringify(oldStorage))
},[currentEndpoint])

	return (
        <div className='accordion'>
            <ul style={{listStyleType:'none'}}>
              {getAccordion(buildJSON)}  
            </ul>
        </div>
	);
};

export default Accordion;
