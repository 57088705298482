import React, { useCallback, useState, useMemo, useEffect } from "react";
import { Outlet,Link } from "react-router-dom";
import ToC from "../../assets/toc.json";
import exJSON from "../../assets/examples.json";
import Accordion from "../../components/accordion/accordion";
import REPLReader from "../../components/REPLReader/replreader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Notes from "../../pages/docsPages/notes";
import Releases from "../../pages/docsPages/futurereleases"
import Ontology from "../../pages/docsPages/ontology"
import Performance from "../../pages/docsPages/performance"


const Docs = () => {

  //----------------STATES-------------------------
  const [currentSection,setCurrentSection] = useState("Quickstart")

  //----------------MEMOS--------------------------
  const TableOfContents = useMemo(()=>ToC,[])
  const exampleJSON = useMemo(()=>exJSON,[])
 //------------USE EFFECTS---------------


  return (
    <div className="container-fluid">
      <div className="row justify-content-end first-row ">
        <div className='row'>
          <div className='col' style={{marginTop:-30}}>
          <p>Dateroll is alternative date library for python. Designed for use cases involving schedule generation and business date adjustments. </p>
              <ul style={{fontSize:'10pt'}}>
                <li><code>dateroll.Date</code> ≈ <code>datetime.date</code></li>
                <li><code>dateroll.Duration</code> ≈ <code>datetime.timedelta</code> or <code>dateutil.relativedelta.relativedelta</code></li>
                <li><code>dateroll.Schedule</code> ≈ <code>dateutil.rrule.rrule</code></li>              
              </ul>
        <br/><br/>
          </div>
        </div>
        <div className="row">
          <div className = "col md-3 xs-12" style={{maxWidth:350}}>
            <Accordion
            buildJSON={TableOfContents}
            onChangeEndpoint={(endpoint)=>setCurrentSection(endpoint)}
            />
            <div style={{paddingLeft:40,marginTop:50}}>
            <p>
              Please star us on <a href='https://github.com/disentcorp/dateroll'>Github</a><br/> or see us on <a href='https://pypi.org/project/dateroll/'>PyPi</a>
            </p>
            
            <p>Most of the examples are interactive, feel free to play wiht the prompts!</p>
              
            <p>Live examples courtesy of <a href="https://repl.disent.com">REPL.disent.com</a>
            </p>
            </div>
          </div>
          
          <div className = 'col md-3 xs-12'>
              <Outlet/>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Docs;
