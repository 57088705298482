import {Link } from "react-router-dom";
import disent_logo from '../../assets/disent_logo_black.png'
import './footer.css'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Footer = () => {


  return (
    <div className="container footer">
      <Row className="">
        <Col>
          <p className="text-center">
            <a href='https://www.disent.com' rel='noreferrer' target="_blank"><img src={disent_logo} className='footer-logo' alt="" /></a>
            <br/>
            <Link to="/privacy">Privacy Policy</Link> | <Link to="/terms">Terms of Service</Link>
          </p>
        </Col>
      </Row>
    </div>

  );
};

export default Footer;
