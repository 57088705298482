

import React, { useCallback, useState, useMemo, useEffect } from "react";
import {Repl} from 'drepl'
import ReactGA from 'react-ga4'

import './replreader.css'
import {
  useParams,
} from 'react-router-dom';
import Notes from "../../pages/docsPages/notes";
import Releases from "../../pages/docsPages/futurereleases"
import Ontology from "../../pages/docsPages/ontology"
import Performance from "../../pages/docsPages/performance"
import exampleJSON from "../../assets/examples.json";

const REPLReader = (props) => {
    const { 
        json = {}
  } = props;

  const REACT_COMPONENT_DOCS = useMemo(()=>{ 
    return {
      "Performance":<Performance/>,
      "Ontology":<Ontology/>,
      "Future": <Releases/>,
     "Notes":<Notes/>,
    }
  
  },[])

  //-------------USE STATES-------------------
  const { page } = useParams();

  const [currentJSONKey,setCurrentJSONKey] = useState(page ? decodeURI(page) : "Quickstart")
//------------HELPER FUNCTIONS-------------------
  const getCurrentRepls = useCallback(() => {
    const currentLibraryRepls = json[currentJSONKey]; //should be list instead
    return currentLibraryRepls.map((exampleParams) => {
      const { imports, exampleName, defaultInput,multiLine, inputRatio, width, height,exampleDescription,disabled,totalLines,defaultOutput,language,widgetSize} = exampleParams;
      const props = { imports, defaultInput, inputRatio, width:widgetSize, height,disabled,totalLines,defaultOutput,language,multiLine}
      return (
        <li key={`${currentJSONKey}:${exampleName}`} style={{ marginBottom: "40px"}}>
          <div className="repl-intro">{exampleName}</div>
          <div className="repl-body"><Repl {...props} /></div>
          <div className="repl-description"
          dangerouslySetInnerHTML={{ __html: exampleDescription }}></div>
        </li>
      );
    });
  }, [json,currentJSONKey]);
//--------------USE MEMOS------------------------

  //------------USE EFFECTS---------------
useEffect(()=>{
  setCurrentJSONKey(page ? decodeURI(page) : "Quickstart")
  ReactGA.send({ hitType: "pageview", page: `/docs/${page}`, title: `Docs: ${page}` });

})

return(
  <>
    { Object.keys(exampleJSON).includes(currentJSONKey) ? 
    <div className='replreader'>
    <ul style={{ listStyleType: "none" }}>{getCurrentRepls()}</ul>
</div>
: 
REACT_COMPONENT_DOCS[page]

  }
  </>

);
}

export default REPLReader;




