import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import TableOfContents from "./assets/toc.json";

import Header from './components/header/header';
import Home from './pages/home';
import ToS from './pages/tos';
import Privacy from './pages/privacy';
import Footer from './components/footer/footer';
import ScrollToTop from './components/scroller/scrolltotop';
import REPLReader from './components/REPLReader/replreader';
import Docs from './components/docs/docs';

import exampleJSON from "./assets/examples.json";


function App() {
  return (
    <BrowserRouter>
    <ScrollToTop/>
    <Routes>
      <Route path="/" element={<Header />}>
        <Route index element={<Navigate to="/docs/Quickstart" replace/>}/>       
        <Route path="/docs" element={<Docs buildJSON={TableOfContents}/>}>
               <Route path=":page" element={<REPLReader json={exampleJSON}/>}/>
        </Route>
        <Route path="/terms" element={<ToS />} />
        <Route path="/privacy" element={<Privacy />} />
        
      </Route>
    </Routes>
    <Footer/>
  </BrowserRouter>
  
  );
}

export default App;
