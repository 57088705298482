const Performance = () => {
    return (
      <div className="container-fluid">
          <div className="row">
            <div className = "col-12">
              <p>Across parsing and date math operations, users can expect a median time of 23µs per operation w/ a std of 29µs (1 core on a intel i7-12700). We tested millions of permutations of all available operations.</p>
              <p>Business day lookups are O(1).</p>
              <p>Several layers of caching are implemented.</p>
            </div>
          </div>
      </div>
    );
  };
  
  export default Performance;
  