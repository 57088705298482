const Releases = () => {
    return (
      <div className="container-fluid">
          <div className="row">
            <div className = "col-12">
              <h2>List of features we would like to add</h2>
              <ul>
                <li>Date adjustment rules - Currently | operator works to adjust a DurationString, we will implement | operator to act on DateString for various modifications such as the "last day of the current month", or the "3rd wednesday of the current month".</li>
                <li>Multitude of daycounters (ACT/365, 30/360, ...)</li>
                <li>New setting for include/exclude first and last dates of a subtraction (with context managers)</li>
                <li>New setting for setting default timezone. (Currently uses system).</li>
                <li>New caching levels</li>
                <li>Intraday support (hours, minutes, seconds, ...)</li>
                <li>Support for holiday lists based on date adjustment rules</li>
                <li>Maybe add lunar/solar/lunisolar conversions.</li>
                </ul>
                <p>Please let us know anything you would to add on github.</p>
            </div>
          </div>
      </div>
    );
  };
  
  export default Releases;
  