const Ontology = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <h2>ddh string's can take several forms:</h2>
          <ul>
            <li>
              <p><code>DateRollString</code> (any of the below)</p>
              <ul>
                <li>
                  <p><code>TodayString</code> (represents today's <code>Date</code>)</p>
                  <ul>
                    <li>Three literal values: <code>"t"</code>,<code> "t0"</code>, or <code>"today"</code></li>
                    <li>Converts to <code>Date</code></li>
                  </ul>
                </li>
                <li>
                  <p><code>DateString</code> (any <code>Date</code>)</p>
                  <ul>
                    <li>Supports 3 <code>conventions</code>: <code>'american'</code>, <code>'european'</code>, and <code>'international'</code>
                      <ul>
                        <li>American: Month + Day + Year</li>
                        <li>European: Day + Month + Year</li>
                        <li>International: Year + Month + Year</li>
                      </ul>
                    </li>
                    <li>Supports for slashes <code>/</code>, dashes <code>-</code> and no separator</li>
                    <li>Converts to <code>Date</code></li>
                  </ul>
                </li>
                <li>
                  <p><code>DurationString</code> (any <code>Duration</code>)</p>
                  <ul>
                    <li><code>DurationPeriodString</code>
                      <ul>
                        <li>Can be &gt;1 pairs of <code>unit</code> and <code>period</code></li>
                        <ul>
                          <li>Optional sign can be either plus <code>+</code> or minus <code>-</code></li>
                          <li>Unit must be valid <code>int</code></li>
                          <li>Valid periods are <code>bd</code>,<code>d</code>,<code>w</code>,<code>m</code>,<code>q</code>, or <code>y</code> </li>
                          <li>Periods can be either lower or uppercase. They represent business days, calendar days, weeks (converts to 7 calendar days), months, quarters (converts to 3 months), and years (respectively).</li>
                          <li>Combinations like <code>5y3m</code> or <code>7w2d</code> or <code>1y2bd</code> are allowed as long as there is only 1 of each period per <code>DurationPeriodString</code>.</li>
                        </ul>
                      </ul>
                    </li>
                    <li><code>CalendarString</code>
                      <ul>
                        <li>Any <code>DurationPeriodString</code> in a <code>DurationString</code> can be followed by the filtration (or pipe) operator <code>"|"</code> to denote information from calendar lists of holidays which must be used to perform date arithmetic.
                          <ul>
                            <li><code>CalendarString</code> follows rules of calendar (2-3 letters A-Z, all uppercase), and union operator <code>"u"</code>
                              <ul>
                                <li>Single calendar, e.g. <code>"|NY"</code> or <code>"|FED"</code></li>
                                <li>Calendar union, e.g. <code>"|NY u LN"</code> (2) or <code>"|FED u BOE u BCB"</code> (3)
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li><code>RollingConventionString</code>
                      <ul>
                        <li>
                          <p>The rolling operator <code>"/"</code> can be followed by MOD string:</p>
                          <ul>
                            <li>When the direction of direction is positive<code>"/MOD"</code> means that if you cross a month boundary, go backwards to 1st business date in that month</li>
                            <li>When the direction of direction is negative<code>"/MOD"</code> means that if you cross a month boundary, go forwards to last business date in that month</li>
                            <li>for reference <a href="https://en.wikipedia.org/wiki/Date_rolling">date rolling</a>, it's for when payment adjustments need to stay in a specific accounting period.</li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Example with one of everything <code>"+ 1y 2h 3s 4q 5m 6w 7d 8bd | NY u LN u JP / MF"</code></p>
                </li>
              </ul>
            </li>
            <li><code>DateMathString</code>
                  <ul>
                    <li>One or more operands separated by an operator</li>
                    <li>Valid operators are plus <code>+</code> or minus <code>-</code></li>
                    <li>Valid operands are either <code>TodayString</code><code>DateString</code> or <code>DurationString</code></li>
                    <li>Examples:</li>
                    <ul>
                      <li><code>t+5m</code> (<code>TodayString</code> plus <code>DurationString</code>)</li>
                      <li><code>4/1/24-4/1/23</code> (<code>DateString</code> minus <code>DateString</code>)</li>
                    </ul>
                  </ul>
                </li>
            <li>
              <p><code>ScheduleString</code> (any <code>Schedule</code>)</p>
              <ul>
                <li>3 parts separated by two commas, e.g.  <code>"X, Y, Z"</code>
                  <ul>
                    <li><code>X</code> is <code>DateString</code>, <code>TodayString</code>, or <code>DateMathString</code></li>
                    <li><code>Y</code> is is <code>DateString</code>, <code>TodayString</code>, or <code>DateMathString</code></li>
                    <li><code>Z</code> is <code>DurationString</code></li>
                  </ul>
                </li>
                <li>yields <code>Schedule(start=X, stop=Y, step=Z)</code></li>
                <li>e.g. <code>"t-5y, 1/15/25, 1y"</code> (from 5 years ago to Jan 15 next year, give me all years)</li>
                
              </ul>
            </li>
            <li>
              <p>Examples</p>
              <ul>
                <li>Unncessarily complicated
                  <ul>
                    <li>
                      <p><code>"t-1y4q5m6w7d8bd|NYuLNuBR/MOD,07/02/1984+45y35bd|FED/MOD,3q7m5d|WE"</code></p>
                      <ul>
                        <li><em>^^ Note</em> on above, if you <code>ddh</code> it, all calendars -unioned together, modified convention would hold as it was specified once, and <code>MOD</code> would depend on the direction of travel when being applied to a specific date.</li>
                        <li>For example, if you  do <code>"t+1m-21bd"</code>, if you have an anchor date, if <code>1m</code> &gt; <code>21bd</code> (net adjustment is in the future as opposed to past) it assumes you are rolling foward, period conversion is used. If modified was specified it would be inherited as well.</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>Classic
                  <ul>
                    <li>Client wants a 5 year loan from today, paid monthly, i need dates to structure it <code>ddh("t,t+5y,1m")</code>.</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Ontology;
