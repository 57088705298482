import { Outlet, Link } from "react-router-dom";
import React from "react";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import discord_logo from "../../assets/discord-mark-black.png";
import letter from "../../assets/email.png";
import drollblue from "../../assets/drollblue.png";

const Header = () => {
	return (
		<div>
			<nav>
				<div className="container-fluid">
					<Row style={{ backgroundColor: "black", color: "white" }}>
						<Col>
							<div>
								<Link
									to="/"
									style={{ display: "inline-block", color: "white", fontSize: "39pt", textDecoration: 'none' }}
								>
									<img alt='Dateroll' src={drollblue} style={{ height: '0.9em', marginLeft: 5, marginTop: -12 }}></img>
									&nbsp;
									<span style={{ fontFamily: 'Consolas, SF Mono, Menlo, Courier, monospace' }}>dateroll</span>
								</Link>
							</div>
						</Col>
					</Row>
					<Row >
						<Col>
							<p style={{ 'textAlign': 'right', fontSize: '18pt' }}>talk to us
								<a href="mailto://repl@disent.com?subject=[repl.disent.com] &body=Hello!"><img src={letter} style={{ height: '22px', paddingLeft: 15 }} alt='email' /></a>
								<a href="https://discord.gg/PFQxJMm4Kb"><img src={discord_logo} style={{ height: '22px', paddingLeft: 15 }} alt='discord' /></a>
							</p>
						</Col>
					</Row>
				</div>
			</nav>
			<Outlet />
		</div>
	);
};

export default Header;
